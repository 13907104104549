import HttpProxy from '@/services/HttpProxy';

export default class FileService {
  static async create(data, headers = undefined) {
    const response = await HttpProxy.submit({
      method: 'post',
      url: 'Files',
      data,
      headers
    });
    return response?.data ?? null;
  }

  static async delete(data) {
    const response = await HttpProxy.submit({
      method: 'delete',
      url: 'Files',
      data,
    });
    return response?.data ?? null;
  }
}
